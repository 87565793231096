
/* center v h */
.centervh{
  display: table;
  min-width: 100%;
  min-height: 100%;
}
.centervh-content{
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}
.centervh-item{
  display: inline-block;
}
.max-screen{
  width: 100vw;
  height: 100vh;
  position: relative;
}
.max-height{
  height: 100%;
}
/* end center v h */

.home-container{
  width: 100vw;
  height: calc(100vh - 3.5rem);
  position: relative;
  top: 3.5rem;
}
.header {
  width: 100%;
  height: 3rem;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 0 1rem; */
  background-color: rgb(196, 220, 250);
  z-index: 100;
  transition: .5s
}
.header-title{
  font-weight: bold;
  margin: 0 1rem;
}
.header-img {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  min-width: 35px;
  margin: 0 1rem;
}
.header-img img {
  width: 40px
}
.chat-input{
  margin: 15px 0;
}
.cursor-default{
  cursor: default;
}
.cursor-pointer{
  cursor: pointer;
}
.cs-conversation__content{
  display: flex !important;
}
.cs-conversation{
  align-items: center;
}
@media screen and (max-width: 768px) {
  .cs-conversation__content{
    margin-left: 1rem;
  }
}
.home-me-avatar{
  margin: 0px !important;
}
.home-me:hover{
  background: none;
}
.user-avatar{
  border-radius: 50%;
  max-width: 25vh;
  max-height: 25vh;
  min-height: 25vh;
  min-width: 25vh;
}

.about{
  width: 100vw;
  height: calc(100vh - 1.5rem);
  background-color: azure;
}